<template>
	<div>
		<!-- 公共头部 -->
<Header></Header>
		
		<div class="section1">
			<router-link to="/personal_regist" replace class="item">碳排放岗位证报名</router-link>
			<!-- <router-link to="/team_regist" replace class="item active">碳排放岗位证报名</router-link> -->
		</div>
		
		<div class="section2">

			<ul class="list">
				<li class="item" v-for="(item,index) in list">
					<div class="pic" v-if="index % 5 == 0" style="background: #63d7ff;">
						<img class="icon" src="@/assets/images/icon25.png" />
					</div>
					<div class="pic" v-if="index % 5 == 1" style="background: #ffc65e;">
						<img class="icon" src="@/assets/images/icon25.png" />
					</div>
					<div class="pic" v-if="index % 5 == 2" style="background: #58f2f0;">
						<img class="icon" src="@/assets/images/icon25.png" />
					</div>
					<div class="pic" v-if="index % 5 == 3" style="background: #fe929e;">
						<img class="icon" src="@/assets/images/icon25.png" />
					</div>
					<div class="pic" v-if="index % 5 == 4" style="background: #36cea9;">
						<img class="icon" src="@/assets/images/icon25.png" />
					</div>
					<h2 class="title">{{item.title}}</h2>
					<div class="desc">{{item.chuanzhen}}</div>
				</li>
			</ul>
<!-- 			<div class="button-box">
				<a :href="config.template" download class="btn">
					<span class="text">报名模板下载</span>
				</a>
			</div> -->
			
		</div>
		
		<!-- 公共底部 -->
		<!-- <Footer></Footer> -->
	</div>
</template>

<script>
	import Header from '@/components/Public/Header.vue';
	import Footer from '@/components/Public/Footer.vue';
	export default {
		components: {
			Header,
			Footer
		},
		data() {
			return {
				title: '碳排放岗位证报名',
				list: [],
			}
		},
		computed: {
			config() {
				return this.$store.state.configInfo;
			}
		},
		activated() {
			this.getList();
		},
		deactivated() {
			this.list = [];
		},
		methods: {
			async getList() {
				this.$toast.loading('加载中...');
				const { data:res } = await this.$http.get('/school/school');
				this.$toast.close();
				if(this.$store.state.beta){
					console.log('碳排放岗位证报名 学校列表：' + new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds() , res);
				}
				var code = res.code;
				if(code == 1) {
					this.list = res.data;
				} else {
					this.list = [];
				}
			},
		},
	};
</script>

<style scoped>
	.section1{
		display: flex;
		align-items: center;
		justify-content: space-between;
		background: #ffffff;
		box-shadow: 0 0 0.1rem rgba(0,0,0,.2);
	}
	.section1 .item{
		flex: 1;
		font-size: 0.28rem;
		color: #969897;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 0.8rem;
	}
	.section1 .item.active{
		font-size: 0.32rem;
		color: #FFFFFF;
		position: relative;
		background: #86cb08;
	}

	
	.section2{
		padding: 0.3rem;
	}
	.section2 .tip{
		font-size: 0.28rem;
		color: #ff6000;
		line-height: 0.42rem;
		padding-left: 0.46rem;
		background: url(../../assets/images/icon22.png) no-repeat left top 0.1rem;
		background-size: 0.34rem 0.35rem;
	}
	.section2 .list{
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;
		margin-top: 0.3rem;
	}
	.section2 .list .item{
		width: 3.25rem;
		height: 2.09rem;
		background: #ffffff;
		box-shadow: 0 0 0.1rem rgba(0,0,0,.2);
		border-radius: 0.16rem;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin-top: 0.4rem;
	}
	.section2 .list .item:nth-child(1),
	.section2 .list .item:nth-child(2){
		margin-top: 0;
	}
	.section2 .list .item .pic{
		width: 0.8rem;
		height: 0.8rem;
		border-radius: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.section2 .list .item .pic .icon{
		width: 0.44rem;
		height: 0.37rem;
	}
	.section2 .list .item .title{
		font-size: 0.28rem;
		color: #333333;
		margin-top: 0.14rem;
	}
	.section2 .list .item .desc{
		font-size: 0.26rem;
		color: #333333;
		margin-top: 0.1rem;
	}
	.section2 .button-box{
		margin-top: 0.5rem;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.section2 .button-box .btn{
		width: 5.8rem;
		height: 0.8rem;
		display: flex;
		align-items: center;
		justify-content: center;
		border: 0.02rem solid #0372c7;
		border-radius: 0.5rem;
	}
	.section2 .button-box .btn .text{
		font-size: 0.32rem;
		color: #0372c7;
		padding-left: 0.6rem;
		background: url(../../assets/images/icon26.png) no-repeat left center;
		background-size: 0.38rem 0.4rem;
		height: 0.6rem;
		display: flex;
		align-items: center;
	}
</style>